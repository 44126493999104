import React, { useState } from "react";
import InGameLayout from "./layouts/ingame/index.tsx";
import PlayLayout from "./layouts/play/index.tsx";
import "./App.css";
import { SoundProvider, SoundControl } from "./components/sound/index.tsx";
import { GameFlowProvider } from "./context/GameFlowContext.tsx";

const MaintenancePage = () => (
  <div className="maintenance-page">
    <h1>Under Maintenance</h1>
    <p>We're currently updating our systems. Please check back later.</p>
  </div>
);

const App = () => {
  const [inGame, setInGame] = useState(false);
  const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === "true";

  if (isMaintenanceMode) {
    return <MaintenancePage />;
  }

  return (
    <SoundProvider>
      <GameFlowProvider>
        <SoundControl />
        <div className="app">
          {inGame ? <InGameLayout /> : <PlayLayout setInGame={setInGame} />}
        </div>
      </GameFlowProvider>
    </SoundProvider>
  );
};

export default App;
